import { computed } from 'vue'
import { useAuth } from '@/auth'
import { RouteLocationRaw, useRouter } from 'vue-router'
import i18n from '@/plugins/i18n'

export enum Roles {
  Admin = 'admin',
  Member = 'member',
}

// We only add the pages that we are going to use
export enum PagesList {
  ExtractionDetail = 'extractions-id',
  PassportsList = 'passports',
  RequestsList = 'requests',
  RequestsDetail = 'requests-id',
  RequestsNew = 'requests-new',
  MaterialsComparison = 'data-assets-dataAssetId-dataObjectId-comparison',
}

export enum Permissions {
  // Dashboad
  UseDashboardExtractions = 'USE_DASHBOARD_EXTRACTIONS',
  ApproveDashboardInformation = 'APPROVE_DASHBOARD_INFORMATION',
  // Extraction
  CreateExtraction = 'CREATE_EXTRACTION',
  UpdateExtraction = 'UPDATE_EXTRACTION',
  DeleteExtraction = 'DELETE_EXTRACTION',
  UploadDocumentsInExtraction = 'UPLOAD_DOCUMENTS_IN_EXTRACTION',
  AllowQuickExtraction = 'ALLOW_QUICK_EXTRACTION',
  AllowDefineDataAssetExtraction = 'ALLOW_DEFINE_DATA_ASSET_EXTRACTION',
  SelectPassportExtraction = 'SELECT_PASSPORT_EXTRACTION',
  CreatePassportExtraction = 'CREATE_PASSPORT_EXTRACTION',
  // Approval
  ApproveExtractedDocuments = 'APPROVE_EXTRACTED_DOCUMENTS',
  // Requests
  CreateRequests = 'CREATE_REQUESTS',
  UpdateRequests = 'UPDATE_REQUESTS',
  ViewRequests = 'VIEW_REQUESTS',
  AddProductsToRequests = 'ADD_PRODUCTS_TO_REQUESTS',
  AddNewItemsToRequests = 'ADD_NEW_ITEMS_TO_REQUESTS',
  RequestsIngredients = 'REQUESTS_INGREDIENTS',
  CloseRequestsCommunications = 'CLOSE_REQUESTS_COMMUNICATIONS',
  // Data assets
  OpenDataAssets = 'OPEN_DATA_ASSETS',
  CreateDataAssets = 'CREATE_DATA_ASSETS',
  UpdateDataAssets = 'UPDATE_DATA_ASSETS',
  DeleteDataAssets = 'DELETE_DATA_ASSETS', // The 'mark data assets' permissions will be handled by the 'delete' permission for now, because is the unique action generated after mark some data asset
  DownloadDataAssets = 'DOWNLOAD_DATA_ASSETS',
  ShareDataAssets = 'SHARE_DATA_ASSETS',
  // Data objects (Materials)
  SearchMaterials = 'SEARCH_MATERIALS',
  OpenMaterials = 'OPEN_MATERIALS',
  ShareMaterials = 'SHARE_MATERIALS',
  DownloadMaterials = 'DOWNLOAD_MATERIALS',
  DeleteMaterials = 'DELETE_MATERIALS', // The 'mark materials' permissions will be handled by the 'delete' permission for now, because is the unique action generated after mark some material
  AddReferencesToMaterials = 'ADD_REFERENCES_TO_MATERIALS',
  AddInformationToMaterials = 'ADD_INFORMATION_TO_MATERIALS',
  AddInformationItemToMaterials = 'ADD_INFORMATION_ITEM_TO_MATERIALS',
  AddInformationTemplateToMaterials = 'ADD_INFORMATION_TEMPLATE_TO_MATERIALS',
  StoreInSAP = 'STORE_IN_SAP',
  SelectPassportsInMaterials = 'SELECT_PASSPORTS_IN_MATERIALS',
  CompareMaterials = 'COMPARE_MATERIALS',
  SeeDocumentInCompareMaterials = 'SEE_DOCUMENTS_IN_COMPARE_MATERIALS',
  UpdateItemsInCompareMaterials = 'UPDATE_ITEMS_IN_COMPARE_MATERIALS',
  DeleteItemsInCompareMaterials = 'DELETE_ITEMS_IN_COMPARE_MATERIALS',
  SeeVersionHistoryOfMaterials = 'SEE_VERSION_HISTORY_OF_MATERIALS',
  // Passports
  CreatePassports = 'CREATE_PASSPORTS',
  UpdatePassports = 'UPDATE_PASSPORTS',
  DeletePassports = 'DELETE_PASSPORTS',
  // Library
  UploadDocumentsInLibrary = 'UPLOAD_DOCUMENTS_IN_LIBRARY',
  OpenDocumentsInLibrary = 'OPEN_DOCUMENTS_IN_LIBRARY',
  DeleteDocumentsInLibrary = 'DELETE_DOCUMENTS_IN_LIBRARY',
}

export default function useRolesAndPermissions() {
  const auth = useAuth()
  const router = useRouter()
  const permissionDeniedRoute = {
    name: 'error',
    params: {
      statusCode: '403',
      // eslint-disable-next-line
      // @ts-ignore
      message: i18n.global.t('You do not have permissions to view this page'),
    },
  }
  const ROLES_WITH_PERMISSIONS = [
    {
      type: Roles.Admin,
      permissions: Object.values(Permissions), // Assing all the permissions
    },
    {
      type: Roles.Member,
      permissions: [
        Permissions.UploadDocumentsInExtraction,
        Permissions.ApproveDashboardInformation,
        Permissions.UseDashboardExtractions,
        Permissions.SearchMaterials,
        Permissions.ApproveExtractedDocuments,
        Permissions.OpenDataAssets,
        Permissions.UpdateDataAssets,
        Permissions.DownloadDataAssets,
        Permissions.OpenMaterials,
        Permissions.ShareMaterials,
        Permissions.DownloadMaterials,
        Permissions.DeleteMaterials,
        Permissions.AddReferencesToMaterials,
        Permissions.AddInformationToMaterials,
        Permissions.AddInformationItemToMaterials,
        Permissions.AddInformationTemplateToMaterials,
        Permissions.StoreInSAP,
        Permissions.CompareMaterials,
        Permissions.SeeDocumentInCompareMaterials,
        Permissions.UpdateItemsInCompareMaterials,
        Permissions.DeleteItemsInCompareMaterials,
        Permissions.SeeVersionHistoryOfMaterials,
        Permissions.OpenDocumentsInLibrary,
      ],
    },
  ]

  const userRolesWithPermissions = computed(() => {
    let allPermissionsByRoles = ROLES_WITH_PERMISSIONS.filter(
      (role) => auth.user && auth.user.roles.some((saiftyRole: string) => role.type === saiftyRole)
    ).flatMap((role) => role.permissions)
    // Remove duplicates
    allPermissionsByRoles = [...new Set(allPermissionsByRoles)]

    const userRolesPermissions = {
      permissions: allPermissionsByRoles,
      restrictedPages: getRestrictedPagesByPermissions(allPermissionsByRoles).map((p) => p.toString()),
    }

    return userRolesPermissions
  })

  function getRestrictedPagesByPermissions(userPermissions: Permissions[]) {
    let pagesToRestrict = Object.values(PagesList)

    if (
      userPermissions.includes(Permissions.CreateExtraction) &&
      userPermissions.includes(Permissions.UpdateExtraction)
    ) {
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.ExtractionDetail)
    }

    if (
      userPermissions.includes(Permissions.CreatePassports) &&
      userPermissions.includes(Permissions.UpdatePassports) &&
      userPermissions.includes(Permissions.DeletePassports)
    ) {
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.PassportsList)
    }

    if (userPermissions.includes(Permissions.ViewRequests)) {
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.RequestsList)
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.RequestsDetail)
    }

    if (userPermissions.includes(Permissions.CreateRequests)) {
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.RequestsNew)
    }

    if (userPermissions.includes(Permissions.CompareMaterials)) {
      pagesToRestrict = pagesToRestrict.filter((page) => page !== PagesList.MaterialsComparison)
    }

    return pagesToRestrict
  }

  function hasPermission(permission: Permissions) {
    return (
      userRolesWithPermissions.value?.permissions && userRolesWithPermissions.value?.permissions.includes(permission)
    )
  }

  function hasRedirectPermissions(permission: Permissions, path: RouteLocationRaw) {
    if (
      userRolesWithPermissions.value?.permissions &&
      userRolesWithPermissions.value?.permissions.includes(permission)
    ) {
      router.push(path)
    }
  }

  function toErrorPageDueToInsufficientPermissions() {
    router.replace(permissionDeniedRoute)
  }

  return {
    userRolesWithPermissions,
    Permissions,
    permissionDeniedRoute,
    hasPermission,
    hasRedirectPermissions,
    toErrorPageDueToInsufficientPermissions,
  }
}
