const BAYER_CLIENT_ID = '773a7670-64ca-4869-be37-9c75ad240feb'
export const IS_BAYER =
  import.meta.env.VITE_MSAL_CLIENT_ID === BAYER_CLIENT_ID ||
  window.ENV.MSAL_CLIENT_ID === BAYER_CLIENT_ID ||
  (window.ENV.API_MONOLITH_BASE_URL && window.ENV.API_MONOLITH_BASE_URL.includes('bayer'))

export const IS_BASF =
  (import.meta.env.VITE_API_SAIFTY_APIS_BASE_URL && import.meta.env.VITE_API_SAIFTY_APIS_BASE_URL.includes('basf')) ||
  (window.ENV.API_MONOLITH_BASE_URL && window.ENV.API_MONOLITH_BASE_URL.includes('basf'))

export const IS_SAIFTY_CLOUD =
  import.meta.env.VITE_AUTH0_DOMAIN?.includes('saifty.cloud') || window.ENV.AUTH0_DOMAIN?.includes('saifty.cloud')

export const IS_BYK =
  (import.meta.env.VITE_API_SAIFTY_APIS_BASE_URL && import.meta.env.VITE_API_SAIFTY_APIS_BASE_URL.includes('byk')) ||
  (window.ENV.API_MONOLITH_BASE_URL && window.ENV.API_MONOLITH_BASE_URL.includes('byk'))
