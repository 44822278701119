import { reactive, readonly } from 'vue'

import { AuthPlugin, RequiredAuthOptions, SignupOptions, AppAuth0ClientOptions } from '../types'
import createAuth0Client, { Auth0Client, RedirectLoginOptions } from '@auth0/auth0-spa-js'
import { RouteParams } from 'vue-router'
import axios from 'axios'
import useProviderData from './useProviderData'
import { UserSource } from '@coac-gmbh/saifty-main-apis'
import { closeSocketConnection } from '@/composables/useRSockets'

export function setupAuthPluginAuth0(options: RequiredAuthOptions, auth0Options: AppAuth0ClientOptions): AuthPlugin {
  const router = options.router
  const { isAuthenticated, user, loaded, error, waitUntilLoaded, syncUserWithBackend, getUserRoles } = useProviderData()
  let auth0Client: Auth0Client | undefined = undefined
  const LOGOUT_URL = window.location.origin + '/dashboard'
  initialize()

  // This function is autocalled, this is a workaround to use await without making the entire setupAuthPluginAuth0 async
  async function initialize() {
    error.value = undefined
    try {
      auth0Client = await createAuth0Client(auth0Options)
      // If the user is returning to the app after authentication ...
      if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
        // handle the redirect and retrieve tokens
        const { appState } = await auth0Client.handleRedirectCallback()
        await loadUserState()
        // Redirect to wizard if the user is new, else redirect to home or to the redirectTo param,
        // IMPORTANT: The just_registered attribute is loaded in a custom action in Auth0 which checks if the created_at value is not older than 2 minutes
        // We do not check for the login_counts because it remains in 1 for the entire user sessions, which could last for several days until the user logs out
        if (user.value && user.value?.just_registered) {
          const query: RouteParams = {}
          if (typeof appState?.redirectTo === 'string' && !appState.redirectTo.includes('wizard')) {
            query.redirectTo = appState.redirectTo
          }
          router.push({
            name: 'wizard',
            query,
          })
          if (import.meta.env.PROD) {
            // Track the registration conversion in LinkedIn
            window.lintrk('track', { conversion_id: 9808372 })
          }
        } else {
          // Redirect to the redirectTo or to the current page without the code|state|error
          router.push(appState?.redirectTo || window.location.pathname)
        }
      } else {
        // If the URL does not have a authorization code, try to load the user because it could be already authenticated via a cookie or session storage
        await loadUserState()
      }
    } catch (exception) {
      // We add the error so that the navigation guard can redirect to the error page and prevents infinite redirections
      error.value = String(exception)
    } finally {
      // When the load of the auth0client or the current user fails we assume the user is not authenticated,
      // so that the application gets displayed instead of remaining unloaded
      // public pages would be displayed but private pages would get redirected to the error page
      loaded.value = true
    }
  }

  async function loadUserState() {
    if (!auth0Client) {
      throw new Error('Authentication client not initialized')
    }
    const auth0User = await auth0Client.getUser()
    const tokenData = await auth0Client.getIdTokenClaims()
    const token = await getAccessToken()
    user.value = {
      id: auth0User?.sub || 'no-id',
      email: auth0User?.email || 'no-email',
      nickname: auth0User?.nickname || auth0User?.email || 'no-username',
      picture: auth0User?.picture || options.defaultPictureURL,
      just_registered: auth0User?.just_registered || false,
      app_metadata: auth0User?.app_metadata,
      roles: getUserRoles(tokenData),
      isAdmin: (import.meta.env.VITE_ADMIN_EMAILS || window.ENV.ADMIN_EMAILS)
        ?.split(',')
        .map((s) => s.trim())
        .includes(auth0User?.email || ''),
    }

    isAuthenticated.value = await auth0Client.isAuthenticated()
    if (isAuthenticated.value && user.value.email && token) {
      await syncUserWithBackend(user.value.email, UserSource.Auth0, token)
    }
  }

  function changePassword() {
    return axios.post(`https://${auth0Options.domain}/dbconnections/change_password`, {
      client_id: auth0Options.client_id,
      email: user.value?.email,
      connection: auth0Options.dbName,
    })
  }

  function clientNotInitialized() {
    router.push({
      name: 'error',
      params: { statusCode: 500, message: 'The authentication client failed to initialize' },
    })
  }

  function loginWithRedirect(redirectTo?: string) {
    if (!auth0Client) {
      return clientNotInitialized()
    }

    let loginOptions: RedirectLoginOptions | undefined = undefined
    if (redirectTo) {
      loginOptions = {
        appState: { redirectTo },
      }
    }
    auth0Client.loginWithRedirect(loginOptions)
  }

  async function signup({ prefilledEmail }: SignupOptions = {}) {
    if (!auth0Client) {
      return clientNotInitialized()
    }
    const options: RedirectLoginOptions = {}
    options.screen_hint = 'signup'
    if (prefilledEmail) {
      options.login_hint = prefilledEmail
    }
    auth0Client.loginWithRedirect(options)
  }

  async function logout() {
    if (!auth0Client) {
      return clientNotInitialized()
    }
    // Do not forget to configure the logout URL in the Auth0 Application
    await auth0Client.logout({
      returnTo: LOGOUT_URL,
    })
    closeSocketConnection()
    isAuthenticated.value = false
    user.value = undefined
  }

  function getAccessToken() {
    if (!auth0Client) {
      clientNotInitialized()
      throw 'Authentication client not initialized'
    }
    return auth0Client.getTokenSilently()
  }

  /*
   * "reactive" unwraps 'ref's, therefore using the .value is not required.
   * E.g: from "auth.isAuthenticated.value" to "auth.isAuthenticated"
   * but when using destructuring like: { isAuthenticated } = useAuth() the reactivity over isAuthenticated would be lost
   * this is not recommended but in such case use toRefs: { isAuthenticated } = toRefs(useAuth())
   * See: https://v3.vuejs.org/guide/reactivity-fundamentals.html#ref-unwrapping
   * And: https://v3.vuejs.org/guide/reactivity-fundamentals.html#destructuring-reactive-state
   */
  const unWrappedRefs = reactive({
    isAuthenticated,
    user,
    loaded,
    error,
    loginWithRedirect,
    signup,
    logout,
    getAccessToken,
    waitUntilLoaded,
    changePassword,
  })

  return readonly(unWrappedRefs)
}
