import { RouteLocationRaw, Router } from 'vue-router'
import { useAuth } from '@/auth'

export function configureNavigationGuards(router: Router) {
  router.beforeEach(async (to): Promise<boolean | RouteLocationRaw> => {
    const auth = useAuth()
    await auth.waitUntilLoaded()

    // Note: To add the 'isMaintainer' value to some user, set this to the specific user in the auth0 page
    if (
      !to.meta.public &&
      (import.meta.env.VITE_MAINTENANCE_MODE === 'true' || window.ENV.MAINTENANCE_MODE) &&
      !auth.user?.app_metadata?.isMaintainer
    ) {
      return {
        name: 'maintenance',
      }
    }

    return true
  })
}
