import i18n from '@/plugins/i18n'
import { DataPoint, DataPointLocation, NewDataPoint } from '@coac-gmbh/approvals-api/dist/models'
import ghs01 from '@/assets/img/hazard-pictograms/ghs01.png'
import ghs02 from '@/assets/img/hazard-pictograms/ghs02.png'
import ghs03 from '@/assets/img/hazard-pictograms/ghs03.png'
import ghs04 from '@/assets/img/hazard-pictograms/ghs04.png'
import ghs05 from '@/assets/img/hazard-pictograms/ghs05.png'
import ghs06 from '@/assets/img/hazard-pictograms/ghs06.png'
import ghs07 from '@/assets/img/hazard-pictograms/ghs07.png'
import ghs08 from '@/assets/img/hazard-pictograms/ghs08.png'
import ghs09 from '@/assets/img/hazard-pictograms/ghs09.png'

export interface PDFSelectionExtracted {
  location: DataPointLocation
  text: string
}

export interface PDFToolDataPointLocation extends DataPointLocation {
  title?: string
}

export interface SAIFTY_CHECK {
  name?: string
  calculationKey?: string
  notesKey: string
  statusKey: string
}

export const CANONICAL_VERSION = 'SEC_0_1.VERSION'
export const CANONICAL_REVISION_DATE = 'SEC_0_1.REVISION_DATE'
export const CANONICAL_SUBMITTED_DATE = 'SEC_0_1.SUBMITTED_DATE'
export const CANONICAL_LANGUAGE = 'SEC_0_1.LANGUAGE'
export const CANONICAL_NAME = 'SEC_1_1.NAME'
export const CANONICAL_PRODUCT_NUMBER = 'SEC_1_1.NUMBER'
export const CANONICAL_MANUFACTURER = 'SEC_1_3.MANUFACTURER'
export const CANONICAL_SYNONYMS = 'SEC_1_1.SYNONYMS'

export const ACTIVITIES_METADATA_ATTRIBUTES = {
  name: CANONICAL_NAME,
  manufacturer: CANONICAL_MANUFACTURER,
}
export const APPROVALS_METADATA_ATTRIBUTES = {
  version: CANONICAL_VERSION,
  articleNumber: CANONICAL_PRODUCT_NUMBER,
  revisionDate: CANONICAL_REVISION_DATE,
  ...ACTIVITIES_METADATA_ATTRIBUTES,
}
export const ACTIVITIES_INCLUDE_METADATA_ATTRIBUTES = Object.values(ACTIVITIES_METADATA_ATTRIBUTES)
export const APPROVALS_INCLUDE_METADATA_ATTRIBUTES = Object.values(APPROVALS_METADATA_ATTRIBUTES)

// If the item have not translation, we return empty to use the translation by canonical
// We don't want to add the canonicals translations to avoid side effects in the passport list
export const APPROVAL_METADATA_ATTRIBUTES_FOR_MATERIAL = [
  // eslint-disable-next-line
  // @ts-ignore
  { fieldName: CANONICAL_VERSION, translation: i18n.global.t('Version') },
  { fieldName: CANONICAL_REVISION_DATE, translation: i18n.global.t('Revision Date') },
  { fieldName: CANONICAL_SUBMITTED_DATE, translation: i18n.global.t('Submitted') },
  { fieldName: CANONICAL_LANGUAGE, translation: i18n.global.t('Language') },
  { fieldName: CANONICAL_NAME },
  { fieldName: CANONICAL_MANUFACTURER },
  { fieldName: CANONICAL_PRODUCT_NUMBER },
  { fieldName: CANONICAL_SYNONYMS },
]

export const COMPUTED_SAIFTY_CHECKS_KEYWORD = 'SAIFTY_CHECK'

export const ECHA_PREFIX = 'ECHA_'

export const PICTOGRAM_ICON_ITEM_NAME = 'SYMBOL'

export const PICTOGRAMS_ICON_OPTIONS = [
  { id: 'GHS01', src: ghs01 },
  { id: 'GHS02', src: ghs02 },
  { id: 'GHS03', src: ghs03 },
  { id: 'GHS04', src: ghs04 },
  { id: 'GHS05', src: ghs05 },
  { id: 'GHS06', src: ghs06 },
  { id: 'GHS07', src: ghs07 },
  { id: 'GHS08', src: ghs08 },
  { id: 'GHS09', src: ghs09 },
]

export interface RelatedItemsColumn {
  name: string
  canonicalPreffix: string
  colClasses: string
}

export interface ApprovalItemsGroup {
  name: string
  groupNumber: number
  items: DataPoint[]
  itemsFilterless: DataPoint[]
}

export interface ControlsForCreatingRelatedItems {
  isCreating: boolean
  canonicalItemName: string
}
export const SAIFTY_STATUS_SUFFIX = 'CHECK_STATUS'

export const SAIFTY_NOTES_SUFFIX = 'CHECK_NOTES'

export const DEFAULT_ITEMS_GROUP = 'Default'

export const COMPOSITION_KEY = 'COMPOSITION'

export const COMPOSITION_HEADER_KEY = 'COMPOSITION_HEADER'

export const COMPOSITION_COMPONENT_KEY = 'COMPOSITION_COMPONENT'

export const COMPOSITION_CAS_NUMBER_KEY = 'COMPOSITION_CAS'

export const COMPOSITION_CLASSIFICATION_KEY = 'COMPOSITION_CLASSIFICATION'

export const COMPOSITION_CONCENTRATION_KEY = 'COMPOSITION_CONCENTRATION'

export const COMPOSITION_EG_NUMBER_KEY = 'COMPOSITION_EG_NR'

export const COMPOSITION_INDEX_NR_KEY = 'COMPOSITION_INDEX_NR'

export const COMPOSITION_SUFFIXES_LIST = [
  COMPOSITION_COMPONENT_KEY,
  COMPOSITION_CAS_NUMBER_KEY,
  COMPOSITION_CLASSIFICATION_KEY,
  COMPOSITION_CONCENTRATION_KEY,
  COMPOSITION_EG_NUMBER_KEY,
  COMPOSITION_INDEX_NR_KEY,
]

export const COMPOSITION_CANONICAL_SECTION_PREFIX = 'SEC_3_2_6'

export const COMPOSITION_CANONICAL_NAME = `${COMPOSITION_CANONICAL_SECTION_PREFIX}.${COMPOSITION_KEY}`

export const APPROVAL_COMPOSITION_COLUMNS: RelatedItemsColumn[] = [
  {
    name: '',
    canonicalPreffix: COMPOSITION_HEADER_KEY,
    colClasses: 'col-2',
  },
  {
    // eslint-disable-next-line
    // @ts-ignore
    name: i18n.global.t('Name'),
    canonicalPreffix: COMPOSITION_COMPONENT_KEY,
    colClasses: 'col-2',
  },
  {
    name: i18n.global.t('CAS-No'),
    canonicalPreffix: COMPOSITION_CAS_NUMBER_KEY,
    colClasses: 'col-2',
  },
  {
    name: i18n.global.t('Classification'),
    canonicalPreffix: COMPOSITION_CLASSIFICATION_KEY,
    colClasses: 'col-2',
  },
  {
    name: i18n.global.t('Concentration'),
    canonicalPreffix: COMPOSITION_CONCENTRATION_KEY,
    colClasses: 'col-2',
  },
  {
    name: i18n.global.t('EG No.'),
    canonicalPreffix: COMPOSITION_EG_NUMBER_KEY,
    colClasses: 'col-2',
  },
  {
    name: i18n.global.t('Index Nr.'),
    canonicalPreffix: COMPOSITION_INDEX_NR_KEY,
    colClasses: 'col-2',
  },
]

export enum ClassificationCanonicalsSuffixesEnum {
  HeaderKey = 'CLASSIFICATION_HEADER',
  HCode = 'CLASSIFICATION_HCODE',
  HazardStatement = 'CLASSIFICATION_HAZARDSTATEMENTS',
  HazardClass = 'CLASSIFICATION_HAZARDCLASS',
  Category = 'CLASSIFICATION_CATEGORY',
  AddInfo = 'CLASSIFICATION_ADD_INFO',
  Organ = 'CLASSIFICATION_ORGAN',
  RouteOfExposure = 'CLASSIFICATION_ROUT_OF_EXPOSURE',
  Remark = 'CLASSIFICATION_REMARK',
}

export const CLASSIFICATION_SUFFIXES_LIST = Object.values(ClassificationCanonicalsSuffixesEnum).map((i) => i)

export const CLASSIFICATION_CANONICAL_SECTION_PREFIX = 'SEC_2_1'

export const APPROVAL_CLASSIFICATION_COLUMNS: RelatedItemsColumn[] = [
  {
    name: '',
    canonicalPreffix: ClassificationCanonicalsSuffixesEnum.HeaderKey,
    colClasses: 'col-2',
  },
  {
    name: 'HCODE',
    canonicalPreffix: ClassificationCanonicalsSuffixesEnum.HCode,
    colClasses: 'col-1',
  },
  {
    name: i18n.global.t('Hazard statements'),
    canonicalPreffix: ClassificationCanonicalsSuffixesEnum.HazardStatement,
    colClasses: 'col-2',
  },
  {
    name: i18n.global.t('Hazard class'),
    canonicalPreffix: ClassificationCanonicalsSuffixesEnum.HazardClass,
    colClasses: 'col-1',
  },
  {
    name: i18n.global.t('Category'),
    canonicalPreffix: ClassificationCanonicalsSuffixesEnum.Category,
    colClasses: 'col-1',
  },
  {
    name: i18n.global.t('Additional information'),
    canonicalPreffix: ClassificationCanonicalsSuffixesEnum.AddInfo,
    colClasses: 'col-2',
  },
  {
    name: i18n.global.t('Organ'),
    canonicalPreffix: ClassificationCanonicalsSuffixesEnum.Organ,
    colClasses: 'col-1',
  },
  {
    name: i18n.global.t('Route of exposure'),
    canonicalPreffix: ClassificationCanonicalsSuffixesEnum.RouteOfExposure,
    colClasses: 'col-1',
  },
  {
    name: i18n.global.t('Remark'),
    canonicalPreffix: ClassificationCanonicalsSuffixesEnum.Remark,
    colClasses: 'col-1',
  },
]

export const AI_DOCUMENT_EXTRACTION_PERFORMANCE_PERCENTAGE = 90

// This function allow us to apply certain business rules to improve the extraction KPI, then, the function changes the value using the 'change by reference' logic
export function applyAIRulesToTheConfidenceField(isItemValidated: boolean, item: NewDataPoint, cardValue: string) {
  if (!isItemValidated && item.provenance) {
    if (item.data.value !== cardValue) {
      item.provenance.confidence = 0
    }
    if (
      item.data.value === cardValue &&
      typeof item.provenance.confidence === 'number' &&
      item.provenance.confidence < AI_DOCUMENT_EXTRACTION_PERFORMANCE_PERCENTAGE
    ) {
      item.provenance.confidence = 95
    }
  }
}
