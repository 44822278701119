import { ref, watchEffect } from 'vue'
import { AppUser } from '../types'
import { accountManagementApi } from '@/api'
import { UserSource } from '@coac-gmbh/saifty-main-apis'
import axios from 'axios'
import { Roles } from '@/composables/useRolesAndPermissions'

export default function useProviderData() {
  const isAuthenticated = ref(false)
  const user = ref<AppUser>()
  const loaded = ref(false)
  const error = ref<string>()

  async function waitUntilLoaded(): Promise<void> {
    if (loaded.value) {
      return
    }

    return new Promise((resolve) => {
      const stopLoadedWatcher = watchEffect(() => {
        if (loaded.value) {
          resolve()
          stopLoadedWatcher()
        }
      })
    })
  }

  async function syncUserWithBackend(email: string, userProviderSource: UserSource, token: string) {
    try {
      const { data: account } = await accountManagementApi.getUserByEmail({
        email,
      })
      await accountManagementApi.updateRoleGroups({
        accountId: account.id,
        updateRoleGroupsRequest: { idToken: token },
      })
    } catch (error: any) {
      if (axios.isAxiosError(error) && error && error.response && error.response.status === 404) {
        await accountManagementApi.register({
          userRequest: { email, source: userProviderSource, idToken: token },
        })
      } else {
        throw new Error('Error verifying user in the server')
      }
    }
  }

  function getUserRoles(idToken?: { [key: string]: any }): Roles[] {
    const authIdTokenRolesClaim = import.meta.env.VITE_AUTH_ID_TOKEN_ROLES_CLAIM || window.ENV.AUTH_ID_TOKEN_ROLES_CLAIM
    let userFallbackRoles = import.meta.env.VITE_USER_FALLBACK_ROLES || window.ENV.USER_FALLBACK_ROLES
    // Default unsustituted valued because of missing ENV variable
    if (userFallbackRoles === '**USER_FALLBACK_ROLES**') {
      userFallbackRoles = undefined
    }

    const roleAdminTokenSynonyms =
      import.meta.env.VITE_ROLE_ADMIN_TOKEN_SYNONYMS || window.ENV.ROLE_ADMIN_TOKEN_SYNONYMS
    let roleAdminTokenSynonymsArray: string[] = []
    if (roleAdminTokenSynonyms) {
      roleAdminTokenSynonymsArray = roleAdminTokenSynonyms.split(',').map((r) => r.trim())
    }
    const roleMemberTokenSynonyms =
      import.meta.env.VITE_ROLE_MEMBER_TOKEN_SYNONYMS || window.ENV.ROLE_MEMBER_TOKEN_SYNONYMS
    let roleMemberTokenSynonymsArray: string[] = []
    if (roleMemberTokenSynonyms) {
      roleMemberTokenSynonymsArray = roleMemberTokenSynonyms.split(',').map((r) => r.trim())
    }

    let saiftyRoles: Roles[] = []
    if (authIdTokenRolesClaim && idToken && authIdTokenRolesClaim in idToken) {
      const tokenRoles = idToken[authIdTokenRolesClaim] as string[]
      if (tokenRoles.includes(Roles.Admin) || roleAdminTokenSynonymsArray.some((r) => tokenRoles.includes(r))) {
        saiftyRoles.push(Roles.Admin)
      }
      if (tokenRoles.includes(Roles.Member) || roleMemberTokenSynonymsArray.some((r) => tokenRoles.includes(r))) {
        saiftyRoles.push(Roles.Member)
      }
    } else if (userFallbackRoles) {
      const userFallbackRolesArray = userFallbackRoles.split(',').map((r) => r.trim())
      if (userFallbackRolesArray.includes(Roles.Admin)) {
        saiftyRoles.push(Roles.Admin)
      }
      if (userFallbackRolesArray.includes(Roles.Member)) {
        saiftyRoles.push(Roles.Member)
      }
    }

    if (saiftyRoles.length === 0) saiftyRoles = [Roles.Member]
    return saiftyRoles
  }

  return {
    isAuthenticated,
    user,
    loaded,
    error,
    waitUntilLoaded,
    syncUserWithBackend,
    getUserRoles,
  }
}
