import { reactive, readonly } from 'vue'
import { AuthPlugin, RequiredAuthOptions } from '../types'
import useProviderData from './useProviderData'
import { UserSource } from '@coac-gmbh/saifty-main-apis'
import axios from 'axios'
import { closeSocketConnection } from '@/composables/useRSockets'
import { addToast } from '@/composables/useToasts'

export interface OAuth2Configuration {
  clientId: string
  scopes: string
  authorizationEndpoint: string
  tokenEndpoint: string
  userInfoEndpoint: string
  logoutEndpoint: string
  redirectUri: string
}

const STORAGE_ACCESS_TOKEN_KEY = 'SAIFTY_ACCESS_TOKEN'
const STORAGE_PKCE_CODE_VERIFIER = 'SAIFTY_PKCE_CODE_VERIFIER'

export function setupAuthPluginOAuth2(options: RequiredAuthOptions, oauth2Config: OAuth2Configuration): AuthPlugin {
  const router = options.router
  const { isAuthenticated, user, error, loaded, waitUntilLoaded, syncUserWithBackend, getUserRoles } = useProviderData()
  initialize()

  // This function is autocalled, this is a workaround to use await without making the entire composable async
  async function initialize() {
    error.value = undefined
    try {
      // If the user is returning to the app after authentication ...
      if (window.location.search.includes('code=')) {
        // handle the redirect and retrieve tokens
        // const { appState } = await handleRedirectCallback()
        await handleRedirectCallback()
        await loadUserState(true)
        // TODO: Redirect to the redirectTo or to the current page without the code|state|error
        // Maybe read the next URL parameter if existing
        // router.push(appState?.redirectTo || window.location.pathname)
        router.push(window.location.pathname)
      } else {
        await loadUserState()
      }
    } catch (exception) {
      error.value = String(exception)
      // authenticationError()
      return
    }
    // When the load of the user state or the authorization code flow fails we assume the user is not authenticated,
    // so that the application gets displayed instead of remaining unloaded
    // public pages would be displayed but private pages would get redirected to the error page
    loaded.value = true
  }

  // Use axios to exchange the authentication code for a token
  async function handleRedirectCallback() {
    if (!window.location.search.includes('code=')) {
      return
    }

    const authorizationCode = new URLSearchParams(window.location.search).get('code')
    const params = new URLSearchParams()
    params.set('grant_type', 'authorization_code')
    params.set('client_id', oauth2Config.clientId)
    params.set('code', authorizationCode?.toString() || '')
    params.set('redirect_uri', oauth2Config.redirectUri)
    params.set('scope', oauth2Config.scopes)
    params.set('code_verifier', sessionStorage.getItem(STORAGE_PKCE_CODE_VERIFIER) || '')

    const { status, data } = await axios.post(oauth2Config.tokenEndpoint, params)
    if (status === 200) {
      const { access_token } = data
      localStorage.setItem(STORAGE_ACCESS_TOKEN_KEY, access_token)
    }
  }

  async function loadUserState(fromRedirectCallback = false) {
    const token = await getAccessToken()
    if (!token) {
      isAuthenticated.value = false
      return
    }

    try {
      const { data: oauth2User } = await axios.get(oauth2Config.userInfoEndpoint, {
        headers: { Authorization: `${options.axios?.authorizationHeaderPrefix || 'Bearer'} ${token}` },
      })

      user.value = {
        ...oauth2User,
        // TODO: Add roles
      }

      isAuthenticated.value = true
      if (isAuthenticated.value && user.value && user.value.email && token) {
        await syncUserWithBackend(user.value.email, UserSource.Oauth2, token)
      }
    } catch (e) {
      localStorage.removeItem(STORAGE_ACCESS_TOKEN_KEY)
      isAuthenticated.value = false
      if (fromRedirectCallback) {
        // When we come from the redirection callback and this endpoint fails, then we throw the error to avoid an infinite redirection loop
        // The error will be caught by the initialize function and the user will be redirected to the error page
        // When the user is not coming from the redirection callback, then we assume the user is not authenticated
        throw e
      }
    }
  }

  function authenticationError() {
    const urlError = new URLSearchParams()
    urlError.set('statusCode', '500')
    urlError.set('message', 'There was an error while authenticating')
    window.location.href = `/error?${urlError.toString()}`
  }

  // PKCE Functions
  async function generateCodeChallenge(codeVerifier: string) {
    const digest = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(codeVerifier))

    return btoa(String.fromCharCode(...new Uint8Array(digest)))
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
  }

  function generateRandomString(length: number) {
    let text = ''
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }

    return text
  }
  // End PKCE Functions

  async function loginWithRedirect(redirectTo?: string) {
    const codeVerifier = generateRandomString(64) // PKCE
    const codeChallenge = await generateCodeChallenge(codeVerifier) // PKCE
    sessionStorage.setItem(STORAGE_PKCE_CODE_VERIFIER, codeVerifier)

    let authorizationURL = `${oauth2Config.authorizationEndpoint}?response_type=code&client_id=${
      oauth2Config.clientId
    }&scope=${encodeURIComponent(oauth2Config.scopes)}&redirect_uri=${
      oauth2Config.redirectUri
    }&code_challenge_method=S256&code_challenge=${codeChallenge}`

    if (redirectTo) {
      authorizationURL += `&next=${redirectTo}`
    }
    window.location.href = authorizationURL
  }

  async function logout() {
    const params = new URLSearchParams()
    params.set('client_id', oauth2Config.clientId)
    params.set('token', await getAccessToken())

    try {
      await axios.post(oauth2Config.logoutEndpoint, params)
    } finally {
      // Even if the token revoke fails we assume the user is logged out
      localStorage.removeItem(STORAGE_ACCESS_TOKEN_KEY)
      closeSocketConnection()
      isAuthenticated.value = false
      user.value = undefined
    }
  }

  async function signup() {
    loginWithRedirect()
  }

  async function getAccessToken() {
    return localStorage.getItem(STORAGE_ACCESS_TOKEN_KEY) || ''
  }

  async function changePassword() {
    addToast({ text: 'Not implemented', variant: 'warning' })
  }

  const unWrappedRefs = reactive({
    isAuthenticated,
    user,
    loaded,
    loginWithRedirect,
    signup,
    logout,
    getAccessToken,
    waitUntilLoaded,
    changePassword,
  })

  return readonly(unWrappedRefs)
}
